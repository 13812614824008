/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.container {
  width: auto;
  /*max-width: 680px;*/
  padding: 0 15px;
}

.footer {
  background-color: #f5f5f5;
}
